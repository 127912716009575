import React from "react";
import { CogIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import {
  DEPLOY_ENVIRONMENT,
  MARKETING_CHANNELS_VIEW_VAR,
} from "constants/constants";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import CreativeDashboardIcon from "./icons/creative-dashboard";
import CustomerJourneyIcon from "./icons/customer-journeys";
import MarketingOverviewIcon from "./icons/marketing-overview";
import MarketingChannelsIcon from "./icons/marketing-channels";
import MetaChannelIcon from "./icons/meta-channel";
import TikTokChannelIcon from "./icons/tiktok-channel";
import GoogleChannelIcon from "./icons/google-channel";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import SidebarStoreSelector from "@components/Stores/StoreSelectorMenu/SidebarStoreSelector";
import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import Image from "next/image";
import { useRouter } from "next/router";
import { classNames } from "@lib/util-functions";
import InfluencerIcon from "./icons/influencer-icon";
import CooperationsIcon from "./icons/cooperations-icon";
import InfluencerIconSmall from "./icons/influencer-icon-small";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { useReadLocalStorage } from "usehooks-ts";
import PinterestChannelIcon from "./icons/pinterest-channel";
import InfluencerOverviewIcon from "./icons/influencer-overview-icon";
import KnowledgeBase from "./icons/knowledge-base-icon";
import { useLogo } from "@lib/utils";
import ProfitLossIcon from "./icons/profit-loss-icon";
import { posthogFeatureFlagsAtom } from "atoms";
import { useAtom } from "jotai";

type NavigationType = {
  name: string;
  icon: any;
  href?: string;
  disabled: boolean;
  label?: string;
  color?: string;
  defaultOpen?: boolean;
  matchingHrefs?: string[];
  catchAll?: string;
  children?: NavigationType[];
};

const navigation: NavigationType[] = [
  {
    name: "Realtime Dashboard",
    icon: CreativeDashboardIcon,
    href: "/",
    disabled: false,
  },
  {
    name: "Marketing Overview",
    icon: MarketingOverviewIcon,
    href: "/marketing/overview",
    disabled: false,
    label: "",
  },
  {
    name: "Marketing Channels",
    icon: MarketingChannelsIcon,
    disabled: false,
    defaultOpen: true,
    children: [
      {
        name: "Meta Ads",
        icon: MetaChannelIcon,
        href: "/channels/meta/dashboard",
        disabled: false,
        matchingHrefs: [
          "/channels/meta/dashboard",
          "/channels/meta/ads-manager",
        ],
        label: "",
      },

      {
        name: "TikTok Ads",
        icon: TikTokChannelIcon,
        href: "/channels/tiktok/dashboard",
        disabled: false,
        matchingHrefs: [
          "/channels/tiktok/dashboard",
          "/channels/tiktok/ads-manager",
        ],
        label: "",
      },

      {
        name: "Google Ads",
        icon: GoogleChannelIcon,
        href: "/channels/google/dashboard",
        disabled: false,
        matchingHrefs: [
          "/channels/google/dashboard",
          "/channels/google/ads-manager",
        ],
        label: "",
      },
      {
        name: "Pinterest Ads",
        icon: PinterestChannelIcon,
        href: "/channels/pinterest/dashboard",
        disabled: false,
        matchingHrefs: [
          "/channels/pinterest/dashboard",
          "/channels/pinterest/ads-manager",
        ],
        label: "",
        color: "bg-[#ffd85c] text-background-soft",
      },
    ],
  },
  {
    name: "Influencers",
    icon: InfluencerIcon,
    disabled: false,
    defaultOpen: true,
    children: [
      {
        name: "Overview",
        icon: InfluencerOverviewIcon,
        href: "/influencers/overview",
        disabled: false,
      },
      {
        name: "Influencer",
        icon: InfluencerIconSmall,
        href: "/influencers",
        catchAll: "/influencers/*",
        disabled: false,
      },

      {
        name: "Cooperations",
        icon: CooperationsIcon,
        href: "/influencers/cooperations",
        disabled: false,
      },
    ],
  },
  {
    name: "Customer Journeys",
    icon: CustomerJourneyIcon,
    href: "/customer-journeys",
    disabled: false,
  },
  {
    name: "Creative Dashboard",
    icon: CreativeDashboardIcon,
    href: "/creatives/comparison",
    matchingHrefs: ["/creatives/comparison", "/creatives/analysis"],
    label:
      DEPLOY_ENVIRONMENT !== "beta" && DEPLOY_ENVIRONMENT !== "demo"
        ? "Beta"
        : undefined,
    color:
      DEPLOY_ENVIRONMENT !== "beta" && DEPLOY_ENVIRONMENT !== "demo"
        ? "bg-[#ffd85c] text-background-soft"
        : undefined,
    disabled: false,
  },
  {
    name: "Profit & Loss",
    icon: ProfitLossIcon,
    href: "/profit-loss-overview",
    disabled: false,
  },
];

const allHrefs: string[] = [];

for (const item of navigation) {
  item.href && allHrefs.push(item.href);
  if (item.matchingHrefs) allHrefs.push(...item?.matchingHrefs);
  if (item.children) {
    for (const subitem of item.children) {
      subitem.href && allHrefs.push(subitem.href);
      if (subitem.matchingHrefs) allHrefs.push(...subitem?.matchingHrefs);
    }
  }
}

const activeClassNames = "bg-primary-dark text-white dark:text-gray-50";
const normalClassNames =
  "text-sidebar dark:text-gray-50 hover:text-gray-100 dark:hover:text-gray-50 hover:bg-primary-dark group-hover:text-foreground dark:group-hover:text-gray-200";
const disabledClassNames = "bg-gray-700 text-gray-500 cursor-not-allowed";

const Sidebar = () => {
  const { onStoreChanged, selectedStoreIds, selectedStores } =
    useSelectedStores();
  const logo = useLogo();
  const router = useRouter();
  const marketingChannelsView =
    (useReadLocalStorage(MARKETING_CHANNELS_VIEW_VAR) as string) ?? "dashboard";

  return (
    <div className="flex flex-col flex-grow py-6 bg-gray-700 overflow-y-auto">
      <div className="flex items-center flex-shrink-0 px-4">
        <Image width={166.4} height={28} alt="logo" src={logo} priority />
      </div>

      <div className="mt-4 flex-grow flex flex-col ">
        <div className="px-2 mt-4">
          <SidebarStoreSelector
            storeOptions={selectedStoreIds}
            onStoreChanged={onStoreChanged}
          />
        </div>
        <nav
          className="flex-1 mt-6 px-2 space-y-8 bg-gray-700 flex flex-col justify-between"
          aria-label="Sidebar"
        >
          <div className="space-y-3">
            {navigation.map((item) => {
              const catchAllActive =
                item.catchAll && !allHrefs.includes(router.pathname)
                  ? new RegExp(item.catchAll).test(router.pathname)
                  : false;
              const current =
                catchAllActive || (item.href && router.pathname === item.href);
              const childrenActive = item.children
                ?.map(
                  (el) =>
                    el.href === router.pathname ||
                    el.matchingHrefs?.includes(router.pathname)
                )
                .reduce((prev, curr) => prev || curr);

              const className = classNames(
                item.disabled
                  ? disabledClassNames
                  : current
                    ? activeClassNames
                    : normalClassNames,
                "group flex items-baseline py-3 pl-3 pr-1 text-sm font-medium rounded-md"
              );

              const content = (
                <>
                  <span
                    className={classNames(
                      current
                        ? "text-gray-200 dark:text-gray-50 fill-gray-100 group-hover:fill-gray-800 dark:group-hover:fill-gray-200"
                        : "group-hover:text-foreground dark:group-hover:text-gray-200 fill-gray-200 group-hover:fill-gray-800 dark:group-hover:fill-gray-200",
                      "mr-3 flex-shrink-0 h-4 w-4"
                    )}
                    aria-hidden="true"
                  >
                    <item.icon className="fill-inherit" />
                  </span>
                  {item.name}
                </>
              );
              if (item.disabled && !item.children) {
                return (
                  <Tooltip key={item.name}>
                    <TooltipTrigger>
                      <div className={className}>
                        {content}{" "}
                        {item.label && (
                          <p
                            className={`text-xs ml-2 rounded-md py-0.5 px-1 ${
                              item.color
                                ? item.color
                                : "text-foreground bg-gray-600"
                            } inline`}
                          >
                            {item.label}
                          </p>
                        )}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className="w-80">
                        Dashboard not published, yet. As soon as it is released
                        you can access it here.
                      </div>
                    </TooltipContent>
                  </Tooltip>
                );
              }

              return !item.children ? (
                <LinkWithPersistentQuery
                  passHref
                  key={item.name}
                  href={item.disabled ? "#" : item.href ?? ""}
                  className={className}
                >
                  <>
                    {content}{" "}
                    {item.label && (
                      <p
                        className={`text-xs ml-2 rounded-md py-0.5 px-1 ${
                          item.color
                            ? item.color
                            : "text-foreground bg-gray-600"
                        } inline`}
                      >
                        {item.label}
                      </p>
                    )}
                  </>
                </LinkWithPersistentQuery>
              ) : (
                <Disclosure
                  as="div"
                  key={item.name}
                  defaultOpen={item.defaultOpen || childrenActive}
                  className="space-y-1"
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={classNames(
                          item.disabled
                            ? disabledClassNames
                            : current
                              ? activeClassNames
                              : normalClassNames,
                          "group w-full flex items-baseline pl-3 pr-1 py-3 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                        )}
                      >
                        <span
                          className={classNames(
                            current
                              ? "text-gray-200 dark:text-gray-50  fill-gray-100 group-hover:fill-gray-800 dark:group-hover:fill-gray-200"
                              : "group-hover:text-foreground dark:group-hover:text-gray-200 fill-gray-200 group-hover:fill-gray-800 dark:group-hover:fill-gray-200",
                            "mr-3 flex-shrink-0 h-4 w-4  "
                          )}
                          aria-hidden="true"
                        >
                          <item.icon className="fill-inherit" />
                        </span>
                        <span className="flex-1">{item.name}</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-400 rotate-180" : "text-gray-300",
                            "ml-3 flex-shrink-0 h-4 w-4 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                          )}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="space-y-1">
                        {item.children?.map((subItem) => {
                          const subCatchAllActive =
                            subItem.catchAll &&
                            !allHrefs.includes(router.pathname)
                              ? new RegExp(subItem.catchAll).test(
                                  router.pathname
                                )
                              : false;
                          const currentSubActive =
                            !subItem.disabled &&
                            (subCatchAllActive ||
                              (subItem.href &&
                                (router.pathname === subItem.href ||
                                  subItem.matchingHrefs?.includes(
                                    router.pathname
                                  ))));
                          let href = subItem.href;
                          if (item.name === "Marketing Channels") {
                            href =
                              subItem.matchingHrefs?.find((el) =>
                                el.includes(`${marketingChannelsView}`)
                              ) ?? subItem.href;
                          }
                          const subClassName = `group w-full flex items-center pl-11 pr-3 py-3 text-sm font-medium  rounded-md ${
                            subItem.disabled
                              ? disabledClassNames
                              : currentSubActive
                                ? activeClassNames
                                : normalClassNames
                          }`;

                          const content = (
                            <span className="flex items-center ">
                              <span
                                className={classNames(
                                  currentSubActive
                                    ? "text-gray-200 dark:text-gray-50 fill-gray-100 group-hover:fill-gray-800 dark:group-hover:text-gray-100 dark:group-hover:fill-gray-200"
                                    : "group-hover:text-gray-200 fill-gray-200 group-hover:fill-gray-800 dark:group-hover:text-gray-100 dark:group-hover:fill-gray-200",
                                  "mr-3 flex-shrink-0 h-4 w-4"
                                )}
                                aria-hidden="true"
                              >
                                <subItem.icon className="fill-inherit" />
                              </span>
                              {subItem.name}
                            </span>
                          );

                          if (subItem.disabled) {
                            return (
                              <div className={subClassName} key={subItem.name}>
                                {content}{" "}
                                {subItem.label && (
                                  <p className="text-foreground text-sm ml-2 rounded-md py-0.5 px-1 bg-gray-600 inline">
                                    {subItem.label}
                                  </p>
                                )}
                              </div>
                            );
                          }
                          return (
                            <LinkWithPersistentQuery
                              href={href ?? subItem.href ?? ""}
                              key={subItem.name}
                              legacyBehavior
                              passHref
                            >
                              <Disclosure.Button
                                className={subClassName}
                                as="a"
                              >
                                {content}
                                {subItem.label && (
                                  <p
                                    className={`text-xs ml-2 rounded-md py-0.5 px-1 ${
                                      subItem.color
                                        ? subItem.color
                                        : "text-foreground bg-gray-600"
                                    } inline`}
                                  >
                                    {subItem.label}
                                  </p>
                                )}
                              </Disclosure.Button>
                            </LinkWithPersistentQuery>
                          );
                        })}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              );
            })}
          </div>

          <div>
            <a
              className="text-sidebar hover:bg-primary-dark hover:text-gray-100 dark:text-gray-100 dark:hover:text-gray-100 group flex items-center p-3 text-sm font-medium rounded-md"
              href={"https://tracify.learningsuite.io/"}
              target="_blank"
              rel="noreferrer"
            >
              <span
                className={
                  "group-hover:text-gray-200 fill-gray-200 group-hover:fill-gray-800 dark:group-hover:text-gray-100 dark:group-hover:fill-gray-200 mr-3 flex-shrink-0 h-4 w-4"
                }
                aria-hidden="true"
              >
                <KnowledgeBase />
              </span>
              Academy
            </a>
            <LinkWithPersistentQuery
              passHref
              href={"/settings"}
              className={classNames(
                router.pathname === "/settings"
                  ? "text-gray-100 bg-primary-dark hover:text-gray-100 dark:text-gray-100 dark:hover:text-gray-100 "
                  : "text-sidebar hover:text-gray-100 hover:bg-primary-dark",
                "group flex items-center p-3 text-sm font-medium rounded-md"
              )}
            >
              <>
                <span
                  className={classNames(
                    router.pathname === "/settings"
                      ? "text-gray-100 dark:text-gray-50"
                      : "text-gray-200 dark:text-gray-50  hover:text-gray-100 group-hover:text-gray-100 ",
                    "mr-3 flex-shrink-0 h-4 w-4"
                  )}
                  aria-hidden="true"
                >
                  <CogIcon className={`h-5 w-5`} />
                </span>
                Settings
              </>
            </LinkWithPersistentQuery>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
