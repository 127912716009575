import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { Button } from "@ui";
import { endTimeAtom, startTimeAtom } from "atoms";
import dayjs from "dayjs";
import { ExportType } from "interface/ExportType";
import { useAtom } from "jotai";
import React, { useMemo } from "react";

type Props = {
  channel: string;
  exportType: ExportType;
  onDownloadFile: ({
    channel,
    fileName,
    exportType,
  }: {
    channel: string;
    fileName: string;
    exportType: ExportType;
  }) => void;
};

const DownloadFileButton = ({
  onDownloadFile,
  channel,
  exportType = "all",
}: Props) => {
  const [startTime] = useAtom(startTimeAtom);
  const [endTime] = useAtom(endTimeAtom);

  const dateRange = useMemo(() => {
    if (dayjs(startTime).isSame(endTime, "day")) {
      return dayjs(startTime).format("YYYY-MM-DD");
    } else {
      return `${dayjs(startTime).format("YYYY-MM-DD")}_${dayjs(endTime).format(
        "YYYY-MM-DD"
      )}`;
    }
  }, [endTime, startTime]);

  const fileName =
    channel === "all"
      ? `tracify_export_${channel}_${dateRange}.csv`
      : `tracify_export_${channel}_${exportType}_${dateRange}.csv`;

  return (
    <button
      onClick={() =>
        onDownloadFile({
          channel: channel,
          fileName: fileName,
          exportType: exportType,
        })
      }
      key={channel}
      className="ph-no-capture w-full px-2 py-1 rounded-lg bg-gray-700 flex items-center text-primary-light duration-200 ease-out transition-colors hover:text-primary"
    >
      <p className="flex-1  font-bold text-left text-sm mr-2">{fileName}</p>
      <Button
        elementType="span"
        size="small"
        type="text"
        iconButton
        icon={<ArrowDownTrayIcon />}
      />
    </button>
  );
};

export default DownloadFileButton;
