/* This example requires Tailwind CSS v2.0+ */
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Image from "next/image";
import { useRouter } from "next/router";
import { invalidateToken, useAuth } from "@lib/api-hooks";
import { DEPLOY_ENVIRONMENT } from "constants/constants";
import { Spinner } from "@ui";
import Sidebar from "./Sidebar";
import { useAtom } from "jotai";
import {
  atomsInitializedAtom,
  dashboardAccessAtom,
  openExportModalAtom,
  posthogFeatureFlagsAtom,
  selectedFiltersAtom,
  showAttributionModeSurveyAtom,
} from "atoms";
import { ScopedRoute } from "@components/ScopedRoute/ScopedRoute";
import { DashboardPageInterface } from "interface/DashboardPageInterface";
import ExportModal from "@components/ExportModal/ExportModal";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { usePostHog } from "posthog-js/react";
import { useLogo } from "@lib/utils";
import Script from "next/script";

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    Intercom?: any;
    Tally?: any;
  }
}

export default function AppLayout({
  children,
  pageProps,
}: {
  children?: React.ReactNode;
  pageProps: DashboardPageInterface;
}) {
  const {
    selectedOrganisationData,
    isPending: isPendingStores,
    selectedStores,
  } = useSelectedStores();
  const logo = useLogo();
  // const { setLoadedTally } = useTallyForms();
  const posthog = usePostHog();
  const [posthogFeatureFlags, setPosthogFeatureFlags] = useAtom(
    posthogFeatureFlagsAtom
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [exportModalOpen] = useAtom(openExportModalAtom);
  const router = useRouter();
  const { data, isPending: isPendingAuth } = useAuth({
    redirect: "/login",
    approvals: pageProps?.approvals,
  });
  const isPending = isPendingAuth || isPendingStores;
  const [dashboardAccess] = useAtom(dashboardAccessAtom);
  const [atomsInitialized] = useAtom(atomsInitializedAtom);
  const [showAttributionModeSurvey] = useAtom(showAttributionModeSurveyAtom);
  useEffect(() => {
    // needed to close the sidebar whenever we change route
    const closeSidebar = () => setSidebarOpen(false);
    router.events.on("routeChangeComplete", closeSidebar);

    return () => {
      router.events.off("routeChangeComplete", closeSidebar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      data?.payload?.tid &&
      (window.Intercom || posthog) &&
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF &&
      DEPLOY_ENVIRONMENT !== "demo"
    ) {
      const obj: any = {
        api_base: "https://api-iam.eu.intercom.io",
        app_id: "bq1t6ztc",
        email: data?.payload?.ema,
        userId: data?.payload?.tid,
        user_id: data?.payload?.tid, // duplicating to possible solve 409 duplicate user error, without removing the userId which may already be in use
      };
      if (
        selectedOrganisationData?.id &&
        !data?.payload?.ema?.endsWith("@tracify.ai")
      ) {
        obj.company = {
          name: selectedOrganisationData?.name,
        };
        posthog?.group("company", selectedOrganisationData?.id, obj.company);
        obj.user_current_organisation_id = selectedOrganisationData?.id;
        obj.user_current_organisation_name =
          selectedOrganisationData?.name ?? "";
      }
      // eslint-disable-next-line new-cap
      window?.Intercom && window?.Intercom("boot", obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.payload?.ema,
    data?.payload?.tid,
    selectedOrganisationData?.id,
    selectedOrganisationData?.name,
  ]);
  useEffect(() => {
    if (posthog) {
      posthog.onFeatureFlags((flags) => {
        const featureFlags: Record<string, any> = {};
        for (const flag of flags) {
          featureFlags[flag] = posthog.getFeatureFlagPayload(flag);
        }
        setPosthogFeatureFlags(featureFlags);
      });
    }
  }, [posthog, setPosthogFeatureFlags]);
  useEffect(() => {
    const email = data?.payload?.ema;
    const id = data?.payload?.tid;

    if (id) {
      posthog?.identify(id, {
        email: email,
        user_id: id,
      });
    }
  }, [data?.payload?.ema, data?.payload?.tid, posthog]);

  let content = children;
  if (!isPending && data && !dashboardAccess) {
    // user does not have the rights to access this dashboard
    content = (
      <ScopedRoute
        accessFlag={!dashboardAccess}
        title="Missing access rights"
        message="Thank you for your interest in our Dashboard. Currently you do not have the rights to access this dashboard. Please contact your account manager to request access to it."
      ></ScopedRoute>
    );
  }

  if (isPending || !data || !atomsInitialized) {
    // loading authentication status
    return (
      <div className="w-screen h-screen flex flex-col text-foreground items-center justify-center">
        <div className="absolute bottom-8">
          <Image width={166.4} height={28} priority alt="logo" src={logo} />
        </div>
        <Spinner size="large" className="mt-8" />
        <p className="mt-2 font-semibold">Loading Tracify Dashboard...</p>
      </div>
    );
  }

  return (
    <>
      {/* commented out, because we might need it in the future again, including all implementation logic */}
      {/* <Script
      src="https://tally.so/widgets/embed.js"
      onLoad={() => setLoadedTally(true)}
    /> */}
      <Script id="velaris-script" strategy="afterInteractive">
        {`
      (function(e,t,a,n,i,c,s){e[n]=e[n]||function(){(e[n].q=e[n].q||[]).push(arguments)},c=t.createElement(a),s=t.getElementsByTagName(a)[0],c.id=n,c.src=i,c.async=1,s.parentNode.insertBefore(c,s)}(window, document, 'script', 'velaris_survey', 'https://survey-widget.euw1.velaris.io/widget.js'))
 
      velaris_survey({
        apiKey: 'ZamDDFKaUh9NTR0Ctw1Xd9Ktp0JHLQsW1T2DyB04',
        userEmail: '${data.payload.ema}',
      });
      
 `}
      </Script>

      {exportModalOpen && <ExportModal />}
      <div
        className={`h-full min-h-screen bg-gray-800 text-foreground ${
          showAttributionModeSurvey ? "show-attribution-mode-survey" : ""
        }`}
      >
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-drawer   lg:hidden"
            open={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-700">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-700"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-foreground"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <Sidebar />
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-gray-700">
            <Sidebar />
          </div>
        </div>
        <div className="lg:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-navbar flex justify-between items-center lg:hidden pl-1 pr-3 sm:pr-3 pt-2 pb-2 sm:pl-2 bg-gray-700">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="h-8">
              <Image width={166.4} height={28} alt="logo" src={logo} priority />
            </div>
          </div>
          <main className="flex-1">
            <div className="py-2">
              <div className="mx-auto px-4">{content}</div>
              <div className="border-t border-gray-700 mx-4 pt-4 pb-2 text-sm relative">
                <span>© Tracify GmbH | </span>{" "}
                <a
                  href="https://www.tracify.ai/pages/imprint"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  {" "}
                  Imprint
                </a>{" "}
                |{" "}
                <a
                  href="https://www.tracify.ai/pages/privacy-policy"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Data protection{" "}
                </a>
                <div className="absolute right-16 top-1 max-w-md flex items-center space-x-2">
                  <svg
                    className="h-6 w-6 fill-current shrink-0"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="SupportAgentIcon"
                    aria-label="fontSize medium"
                  >
                    <path d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z"></path>
                    <circle cx="9" cy="13" r="1"></circle>
                    <circle cx="15" cy="13" r="1"></circle>
                    <path d="M18 11.03C17.52 8.18 15.04 6 12.05 6c-3.03 0-6.29 2.51-6.03 6.45 2.47-1.01 4.33-3.21 4.86-5.89 1.31 2.63 4 4.44 7.12 4.47z"></path>
                  </svg>
                  <p>
                    For fastest answers contact us via the chat on the right.{" "}
                    <br /> Alternatively write to{" "}
                    <a href="mailto:support@tracify.ai"> support@tracify.ai </a>
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
